import React from 'react'

class Seenon extends React.Component {
  render () {
    return (
      <>
        
        
        <section class="our-sponsor-client-area section-padding-100">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
                        <p>Media &amp; News</p>
                        <h4>as Seen On</h4>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    
                    <div class="our-sponsor-area d-flex flex-wrap">
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a><img src="img/seenon/suntv.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://www.thehindu.com/life-and-style/food/watch-fully-automated-cooking-thanks-to-robochef/article30025435.ece"><img src="img/seenon/hindu.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://www.youtube.com/watch?v=r57EYBOdJW0" ><img src="img/seenon/polimer.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://www.youtube.com/watch?v=FdFwdWkmW4k"><img src="img/seenon/thanthi.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://www.gitexfuturestars.com/exhibitors/robochef-the-robotic-kitchen"><img src="img/seenon/gitex.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://techcrunch.com/2019/11/12/introducing-the-tc-top-picks-for-disrupt-berlin-2019/"><img src="img/seenon/tc.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://www.youtube.com/watch?v=xk0uUKRYZd4"><img src="img/seenon/vendhar.png" alt=""/></a>
                        </div>
                        
                        <div class="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                            <a target="_blank" href="https://yourstory.com/tamil/robochef-indias-first-robot-cooking-machine-chennai-startup"><img src="img/seenon/yourstory.png" alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


      </>
    )
  }
}

export default Seenon
