import React from 'react'

class All extends React.Component {
  render () {
    return (
      <>
        {/* <!-- Contact Area Start --> */}
        <section className='contact-our-area section-padding-100-0' id='contact'>
          <div className='container'>
            <div className='row'>
              {/* <!-- Heading --> */}
              <div className='col-12'>
                <div className='section-heading-2 text-center wow fadeInUp' data-wow-delay='300ms'>
                  <p>Have Question?</p>
                  <h4>Contact us</h4>
                </div>
              </div>
            </div>

            <div className='row justify-content-between'>

              <div className='col-12 col-sm-7'>
                {/* <!-- Contact Form --> */}
                <div className='contact_from_area mb-100 clearfix wow fadeInUp' data-wow-delay='300ms'>
                  <div className='contact_form'>
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScd8xygLa61pbKmlzH7JsfsAOKKt_oeO7yjzsKFewPV6opvew/viewform?embedded=true" width="100%" height="980" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    {/* <form action='sendEmail.php' method='post' id='main_contact_form'>
                      <div className='contact_input_area'>
                        <div id='success_fail_info' />
                        <div className='row'>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='text' className='form-control mb-30' name='contactName' id='contactName' placeholder='Your Name' />
                            </div>
                          </div>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='email' className='form-control mb-30' name='contactEmail' id='contactEmail' placeholder='E-mail' />
                            </div>
                          </div>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='text' className='form-control mb-30' name='contactSubject' id='contactSubject' placeholder='Your Number' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='form-group'>
                              <textarea name='message' className='form-control mb-30' id='contactMessage' cols='30' rows='6' placeholder='Your Message *' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <button type='submit' className='btn confer-btn'>Send Message <i className='zmdi zmdi-long-arrow-right' /></button>
                          </div>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-4'>
                <div className='contact-information mb-100'>
                  {/* <!-- Single Contact Info --> */}
                  <center><div className='single-contact-info wow fadeInUp' data-wow-delay='300ms'>
                    <p>Address:</p>
                    <h6>Ramapuram,  <br />
                    Chennai - 600089 <br /></h6>
                  </div>
                  {/* <!-- Single Contact Info --> */}
                  <div className='single-contact-info wow fadeInUp' data-wow-delay='300ms'>
                    <p>Phone:</p>
                    <a href="tel:+919789968765"><h6>+91 97899 68765</h6></a>
                    <a href="tel:+919940432545"><h6>+91 99404 32545</h6></a>
                  </div>
                  {/* <!-- Single Contact Info --> */}
                  <div className='single-contact-info wow fadeInUp' data-wow-delay='300ms'>
                    <p>Email:</p>
                    <a href="mailto:robotickitchenindia@gmail.com"><h6>robotickitchenindia@gmail.com</h6></a>
                  </div>
                  
                  {/* <!-- Single Contact Info --> */}
                  <div className='single-contact-info wow fadeInUp' data-wow-delay='300ms'>
                    <p>Website:</p>
                    <h6>robochef.co</h6>
                    
                    <br></br>
                    <br></br>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.94591160122502!2d80.17616770833074!3d13.02710031410195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5260da01d81ecb%3A0x7cbed9499b53d7b2!2sKanniyappan%20St%2C%20Shanthi%20Nagar%2C%20Ramapuram%2C%20Chennai%2C%20Tamil%20Nadu%20600087!5e0!3m2!1sen!2sin!4v1586167515005!5m2!1sen!2sin" width="100%" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" tabIndex="0"></iframe>
                  </div>
                  </center>
                  
                </div>
                
              </div>

            </div>
          </div>
        </section>
      </>
    )
  }
}

export default All
