import React from 'react'

class Solution extends React.Component {
  render () {
    return (
      <>
        
        
        {/* <!-- Our Schedule Area Start --> */}
        
        <section className="what-we-offer-area section-padding-100-70" id='solution'>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading-3 text-center wow fadeInUp" data-wow-delay="300ms">
                        <p>Robochef's Solution</p>
                        <h4>What is the Solution?</h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 col-xl-3">
                    <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                        
                        <div className="offer-icon">
                            <img src="img/solution/precise.png" alt="" />
                        </div>
                        <h5>ACCURACY</h5>
                        <p>Precise measuring cups and sensor fusion technology gives consistent taste.</p>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                    <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                        
                        <div className="offer-icon">
                            <img src="img/solution/noman.png" alt=""/>
                        </div>
                        <h5>CHEF-LESS</h5>
                        <p>We won’t be needing any cook to perform the cooking process in the kitchen</p>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                    <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                       
                        <div className="offer-icon">
                            <img src="img/solution/auto.png" alt=""/>
                        </div>
                        <h5>COOKOPS</h5>
                        <p>CookOps is applying the automation techniques widely adopted in the software world specific to Cooking.</p>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-3">
                    <div className="single-we-offer-content text-center wow fadeInUp" data-wow-delay="0.3s">
                      
                        <div className="offer-icon">
                            <img src="img/solution/safe.png" alt=""/>
                        </div>
                        <h5>SAFETY-FIRST</h5>
                        <p>Zero CO2 emissions <br></br>Safest Kitchen</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        {/* <!-- Our Schedule Area End --> */}


      </>
    )
  }
}

export default Solution
