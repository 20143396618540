import React from 'react'

class Process extends React.Component {
  render () {
    return (
      <>
        
        
        <section className="our-schedule-area section-padding-100">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
                        <p>Process</p>
                        <h4>How it Works?</h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="schedule-tab">
                              {/* <img className="image" src="img/appdesign.jpg" alt=""/> */}
                              {/* <picture>
                                  <source media="(min-width: 767px)" sizes="1px" srcset=""/>
                                  <img src="img/appdesign.jpg" alt="This is an awesome image" style={{paddingTop:"100px", paddingLeft:"630px", width:'100%', height: '950px', position: 'absolute'}}/>
                              </picture> */}
                    </div>

                    <div className="tab-content" id="conferScheduleTabContent" >
                    
                        <div className="tab-pane fade show active" id="step-one" role="tabpanel" aria-labelledby="monday-tab">
                            
                        
                            
                            <div className="single-tab-content">
                            
                                <div className="row">
                                    <div className="col-12">
                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                <div className="single-schedule-tumb">
                                                    {/* <img src="img/bg-img/10.jpg" alt="" /> */}
                                                    <h3 style={{color: 'white', paddingTop: "25px"}}>1.</h3>
                                                </div>
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>ORDER</h3>
                                                    <p>Design and Order your RoboChef.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                
                                                <div className="single-schedule-tumb">
                                                <h3 style={{color: 'white', paddingTop: "25px"}}>2.</h3>
                                                </div>
                                                
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>SETUP ROBOCHEF</h3>
                                                    <p>We set-up and provide training for your RoboChef.</p>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                
                                                <div className="single-schedule-tumb">
                                                <h3 style={{color: 'white', paddingTop: "25px"}}>3.</h3>
                                                </div>
                                                
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>ROBOCHEF APP</h3>
                                                    <p>Choose a Recipe from our App or RoboChef Control Screen.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                
                                                <div className="single-schedule-tumb">
                                                  <h3 style={{color: 'white', paddingTop: "25px"}}>4.</h3>
                                                </div>
                                                
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>CUSTOMIZE</h3>
                                                    <p>Customize and add recipes to vessel.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                
                                                <div className="single-schedule-tumb">
                                                  <h3 style={{color: 'white', paddingTop: "25px"}}>5.</h3>
                                                </div>
                                                
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>START COOKING</h3> 
                                                    <p>Load the ingredients and select Start Cooking.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp" data-wow-delay="300ms">
                                            
                                            <div className="single-schedule-tumb-info-re d-flex align-items-center">
                                                
                                                <div className="single-schedule-tumb">
                                                  <h3 style={{color: 'white', paddingTop: "25px"}}>6.</h3>
                                                </div>
                                                
                                                <div className="single-schedule-info">
                                                    <h3 style={{color: 'white'}}>SERVE / DELIVER</h3>
                                                    <p>Serve to your customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </section>
        {/* <!-- Our Schedule Area End --> */}


      </>
    )
  }
}

export default Process
