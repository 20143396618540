import React from 'react'

class About extends React.Component {
  render () {
    let aboutText1, aboutText2, aboutPoints, aboutImage, aboutTopic
    if (this.props.data === undefined) {
      aboutTopic = 'About Us'
      aboutText1 =  'RoboChef - World\'s First Fully Automated Robotic Kitchen'
      aboutText2 =  'that Cooks 800+ recipes with ZERO manual effort powered by IoT, Robotics & Machine Learning.'
      aboutPoints = [
        'Customizable',
        'Fully Automated',
        'Consistency',
        'Record Recipe Once, Cook Forever'
      ]
      aboutImage = 'img/core-img/robochef_co.jpg'
    } else {
      aboutTopic = this.props.data.aboutTopic
      aboutText1 = this.props.data.aboutText1
      aboutText2 = this.props.data.aboutText2
      aboutPoints = this.props.data.aboutPoints
      aboutImage = this.props.data.aboutImage
    }

    return (
      <section className='about-us-countdown-area section-padding-100-0' id='about'>
        <div className='container'>
          <div className='row align-items-center'>
            {/* <!-- About Content --> */}
            <div className='col-12 col-md-6'>
              <div className='about-content-text mb-80'><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <h6 className='wow fadeInUp' data-wow-delay='300ms'>{aboutTopic}</h6>
                <h5 className='wow fadeInUp' style={{color: 'yellow'}} data-wow-delay='300ms'>{aboutText1}</h5>
                <p className='wow fadeInUp' data-wow-delay='300ms'>{aboutText2}</p>
                <h6 className='wow fadeInUp' data-wow-delay='300ms'>
                  <ul>
                  <li>Customizable</li><br></br>
                  <li>Fully Automated</li><br></br>
                  <li>Consistency</li><br></br>
                  <li>Record Recipe Once, Cook Forever</li>
                  </ul>
                  </h6><br></br>
                <a href='https://www.youtube.com/watch?v=jgMFj94Y4V4' className='btn confer-btn mt-50 wow fadeInUp lightbox' data-wow-delay='300ms'>Watch Video <i className='zmdi zmdi-long-arrow-right' /></a>
              </div>
            </div>

            {/* <!-- About Thumb --> */}
            <div className='col-12 col-md-6'>
              <div className='about-thumb mb-80 wow fadeInUp' data-wow-delay='300ms'>
              <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <img src={aboutImage} width='540' height='443' loading="lazy" alt='' />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Counter Up Area --> */}
        <div className='countdown-up-area'>
          <div className='container'>
            <div className='row align-items-center'>
              {/* <div className='col-12 col-md-3'>
                <div className='countdown-content-text mb-100 wow fadeInUp' data-wow-delay='300ms'>
                  <h6>Conference Date</h6>
                  <h4>Count Every Second Until the Event</h4>
                </div>
              </div> */}

              <div className='col-12 col-md-9'>
                <div className='countdown-timer mb-100 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <div id='clock' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
