import React from 'react'

class All extends React.Component {
  render () {
    return (
      <>
        <section className='our-speaker-area bg-img bg-gradient-overlay section-padding-100-60' style={{ backgroundImage: 'url(/img/bg-img/bg-2.jpg)' }}>
          <div className='container'>
            <div className='row'>
              {/* <!-- Heading --> */}
              <div className='col-12'>
                <div className='section-heading text-center wow fadeInUp' data-wow-delay='300ms'>
                  <p>Our Team</p>
                  <h4>Following are our team members</h4>
                </div>
              </div>
            </div>

            <div className='row'>
              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='http://cdn.onlinewebfonts.com/svg/img_507349.png' alt='' />
                  </div>
                  {/* <!-- Social Info --> */}
                  <div className='social-info'>
                    {/* <a href='#'><i className='zmdi zmdi-facebook' /></a>
                    <a href='#'><i className='zmdi zmdi-instagram' /></a> */}
                    <a href='#'><i className='zmdi zmdi-twitter' /></a>
                    <a href='#'><i className='zmdi zmdi-linkedin' /></a>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Saravanan Sundaramoorthy</h5>
                    <p>Founder & CEO</p>
                  </div>
                </div>
              </div>

              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='https://static1.squarespace.com/static/57996996e6f2e17cc7257a63/t/5982b217cd0f682edf795d62/1501737506567/Screen+Shot+2016-10-26+at+10.21.24+AM.png' alt='' />
                  </div>
                  {/* <!-- Social Info --> */}
                  <div className='social-info'>
                    <a href='#'><i className='zmdi zmdi-facebook' /></a>
                    <a href='#'><i className='zmdi zmdi-instagram' /></a>
                    <a href='#'><i className='zmdi zmdi-twitter' /></a>
                    <a href='#'><i className='zmdi zmdi-linkedin' /></a>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Hema Saravanan</h5>
                    <p>Head of Operations</p>
                  </div>
                </div>
              </div>

              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='https://www.freeiconspng.com/uploads/male-icon-19.png' alt='' />
                  </div>
                  {/* <!-- Social Info --> */}
                  <div className='social-info'>
                    <a href='#'><i className='zmdi zmdi-facebook' /></a>
                    <a href='#'><i className='zmdi zmdi-instagram' /></a>
                    <a href='#'><i className='zmdi zmdi-twitter' /></a>
                    <a href='#'><i className='zmdi zmdi-linkedin' /></a>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Adithya J</h5>
                    <p>CTO</p>
                  </div>
                </div>
              </div>

            <center></center>

              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='https://cdn4.iconfinder.com/data/icons/engineering-13/50/54-512.png' alt='' />
                  </div>
                  {/* <!-- Social Info --> */}
                  <div className='social-info'>
                    <a href='#'><i className='zmdi zmdi-facebook' /></a>
                    <a href='#'><i className='zmdi zmdi-instagram' /></a>
                    <a href='#'><i className='zmdi zmdi-twitter' /></a>
                    <a href='#'><i className='zmdi zmdi-linkedin' /></a>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Vignesh Kumar M</h5>
                    <p>Technical Architect</p>
                  </div>
                </div>
              </div>

              {/* <div className='col-12'>
                <div className='more-speaker-btn text-center mt-20 mb-40 wow fadeInUp' data-wow-delay='300ms'>
                  <a className='btn confer-btn-white' href='#'>view all Speaker <i className='zmdi zmdi-long-arrow-right' /></a>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- Our Speakings Area End --> */}
      </>
    )
  }
}

export default All
