import React from 'react'
import 'lazysizes'

class Services extends React.Component {
  render () {
    let techTitle; let techSubtitle; let techTitle1; let techTitle2; let techTitle3; let techTitle4; let techTitle5; let techTitle6; let techDesc1; let techDesc2; let techDesc3; let techDesc4; let techDesc5; let techDesc6; let techPoints1 = []; let techPoints2 = []; let techPoints3 = []; let techPoints4 = []; let techPoints5 = []; let techPoints6 = []

    let techImage1 = []; let techImage2 = []; let techImage3 = []; let techImage4 = []; let techImage5 = []; let techImage6 = []
    if (this.props.data === undefined) {
      techTitle = 'Dishes'
      techSubtitle = 'The following are the few of the dishes served by our restaurant.'
      techTitle1 = 'Prawn Masala'
      techTitle2 = 'Coconut Rice'
      techTitle3 = 'Egg Kurma'
      techTitle4 = 'Paneer Butter Masala'
      techTitle5 = 'Tamarind Rice'
      techTitle6 = 'Sakkarai Pongal'
      techDesc1 = 'Prawns Masala is a spicy Prawns dish. Royyala means Prawns in Telugu, and Iguru means thick gravy. As you all know, in India, Andhra food is labelled as the spiciest, and this typical Prawns curry.'
      techDesc2 = 'Thengai sadam recipe - light, mild south indian coconut rice recipe made with fresh grated coconut. It is incredibly easy to make, loaded with flavor and great for lunch box.'
      techDesc3 = 'Egg Kurma is a saucy dish originated in central Asia and is made of spices, meat, vegetables, yogurt, seeds or nut paste. This simple egg kurma is roughly adapted from few of the south indian dishes.'
      techDesc4 = 'Paneer Butter Masala is one of the most popular vegetarian dishes from Indian cuisine. Paneer butter masala also known as butter paneer is a restaurant style rich, creamy and delicious paneer dish prepared using butter.'
      techDesc5 = 'Puliyodarai is usually cooked on special occasions and festive days. It is presented to God as part of prayers also given as prasadam in some temples; devotees queue to get it after darshana.'
      techDesc6 = 'Sweet pongal is a delicious South Indian dish made with rice, moong lentils, ghee, cardamoms and nuts. Sweet pongal is known as chakkara pongali in telugu & sakkarai pongal in tamil.'
    } else {
      techImage1.push(<div className='icon' style={{ background: '#fceef3' }}><i className='ion-ios-analytics-outline' style={{ color: '#ff689b' }} /></div>)
      techImage2.push(<div className='icon' style={{ background: '#fff0da' }}><i className='ion-ios-bookmarks-outline' style={{ color: '#e98e06' }} /></div>)
      techImage3.push(<div className='icon' style={{ background: '#eafde7' }}><i className='ion-ios-speedometer-outline' style={{ color: '#41cf2e' }} /></div>)
      techImage4.push(<div className='icon' style={{ background: '#e6fdfc' }}><i className='ion-ios-paper-outline' style={{ color: '#3fcdc7' }} /></div>)
      techImage5.push(<div className='icon' style={{ background: '#e1eeff' }}><i className='ion-ios-world-outline' style={{ color: '#2282ff' }} /></div>)
      techImage6.push(<div className='icon' style={{ background: '#ecebff' }}><i className='ion-ios-clock-outline' style={{ color: '#8660fe' }} /></div>)

      techTitle = this.props.data.techTitle
      techSubtitle = this.props.data.techSubtitle
      techTitle1 = this.props.data.techTitle1
      techTitle2 = this.props.data.techTitle2
      techTitle3 = this.props.data.techTitle3
      techTitle4 = this.props.data.techTitle4
      techTitle5 = this.props.data.techTitle5
      techTitle6 = this.props.data.techTitle6
      techDesc1 = this.props.data.techDesc1
      techDesc2 = this.props.data.techDesc2
      techDesc3 = this.props.data.techDesc3
      techDesc4 = this.props.data.techDesc4
      techDesc5 = this.props.data.techDesc5
      techDesc6 = this.props.data.techDesc6
    }

    let techPoints1Html = []
    techPoints1.forEach(point => {
      techPoints1Html.push(<li key={point}>{point}</li>)
    })

    let techPoints2Html = []
    techPoints2.forEach(point => {
      techPoints2Html.push(<li key={point}>{point}</li>)
    })

    let techPoints3Html = []
    techPoints3.forEach(point => {
      techPoints3Html.push(<li key={point}>{point}</li>)
    })

    let techPoints4Html = []
    techPoints4.forEach(point => {
      techPoints4Html.push(<li key={point}>{point}</li>)
    })

    let techPoints5Html = []
    techPoints5.forEach(point => {
      techPoints5Html.push(<li key={point}>{point}</li>)
    })

    let techPoints6Html = []
    techPoints6.forEach(point => {
      techPoints6Html.push(<li key={point}>{point}</li>)
    })

    return (
      <>
        {/* <!-- Our Blog Area Start --> */}
        <section className='our-blog-area bg-img bg-gradient-overlay section-padding-100-60' id='services' style={{ backgroundImage: 'url(/img/bg-img/bg-2.jpg)' }}>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {/* <!-- Heading --> */}
                <div className='section-heading text-center wow fadeInUp' data-wow-delay='300ms'>
                  <h4>{techTitle}</h4>
                  <p>{techSubtitle}</p>
                </div>
              </div>

              {/* <!-- Single Blog Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/prawn-masala2_doz5zi.jpg'width='100%' height='100%' loading="lazy" alt=''  />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/prawn-masala'>{techTitle1}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date'  target="_blank" href='http://robotickitchen.in/blog/prawn-masala'> {techDesc1}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints1Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>

              {/* <!-- Single Blog Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/coconut-rice_rrop6z.jpg' width='100%' height='100%' loading="lazy" alt='' />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/coconut-rice'>{techTitle2}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date' target="_blank" href='http://robotickitchen.in/blog/coconut-rice'>{techDesc2}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints2Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>

              {/* <!-- Single Blog Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/egg-kurma_m9xpff.jpg' width='100%' height='100%' loading="lazy" alt='' />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/egg-kurma'>{techTitle3}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date' target="_blank" href='http://robotickitchen.in/blog/egg-kurma'>{techDesc3}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints3Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>

              {/* <!-- Single Blog Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/paneer-butter_cfoxmy.jpg' width='100%' height='100%' loading="lazy" alt='' height='150' />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/paneer-butter-masala'>{techTitle4}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date' target="_blank" href='http://robotickitchen.in/blog/paneer-butter-masala'>{techDesc4}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints4Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>

                {/* <!-- Single Blog Area --> */}
                <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/tamarind-rice_iva7ob.jpg' width='100%' height='100%' loading="lazy" alt='' />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/tamarind-rice'>{techTitle5}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date' target="_blank" href='http://robotickitchen.in/blog/tamarind-rice'>{techDesc5}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints5Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>

              {/* <!-- Single Blog Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-blog-area wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Single blog Thumb --> */}
                  <div className='single-blog-thumb'>
                    <img src='/img/recipes/s-pongal_w53oup.jpg' width='100%' height='100%' loading="lazy" alt='' />
                  </div>
                  <div className='single-blog-text text-center'>
                    <a className='blog-title' target="_blank" href='http://robotickitchen.in/blog/sakkarai-pongal'>{techTitle6}</a>
                    {/* <!-- Post Meta --> */}
                    {/* <div className='post-meta'>
                      <a className='post-date' target="_blank" href='http://robotickitchen.in/blog/sakkarai-pongal'>{techDesc6}</a>
                    </div> */}
                    {/* <p> */}
                      {/* <ul>
                      {techPoints6Html}
                      </ul> */}
                    {/* </p> */}
                  </div>
                  {/* <div className='blog-btn'>
                    <a href='#'><i className='zmdi zmdi-long-arrow-right' /></a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Our Blog Area End --> */}

      </>
    )
  }
}

export default Services
