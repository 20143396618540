import React from 'react'

class All extends React.Component {
  render () {
    return (
      <>
        
        
        {/* <!-- Our Schedule Area Start --> */}
        
        <section className='our-ticket-pricing-table-area bg-img bg-gradient-overlay section-padding-100-0 jarallax' id='products' style={{backgroundImage: 'url(img/bg-img/bg-2.jpg)'}}>
        <div className='container'>
            <div className='row1'>
                <div className='col-12'>
                    <div className='section-heading text-center wow fadeInUp' data-wow-delay='300ms'>
                        <p>Choose a Model</p>
                        <h4>RoboChef Variants</h4>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-lg-4-1'>
                    <div className='single-ticket-pricing-table text-center mb-100 wow fadeInUp' data-wow-delay='300ms'>
                        <h6 className='ticket-plan'>D Series</h6>
                        <div className='ticket-icon'>
                            <img src='img/core-img/11.jpg' width='100%' height='100%' loading="lazy" alt=''/>
                        </div>
                        <h2 className='ticket-price'>Domestic</h2>
                        <span>Home Use, 24x7 <br></br>On-Demand cooking</span><br></br><br></br>
                        
                        <a href="#contact" className='btn confer-btn w-100 mb-30'>Get Quote <i className='zmdi zmdi-long-arrow-right'></i></a>
                        
                        <div className='ticket-pricing-table-details'>
                            <p><h6>Serves</h6> <i className='zmdi zmdi-check'></i> Min 1 - Max 4</p>
                            <p><h6>Vessel Capacity</h6><i className='zmdi zmdi-check'></i> 3.7 Litres </p>
                            <p><h6>Parallel Cooking</h6><i className='zmdi zmdi-check'></i> 1 Recipe only</p>
                            {/* <p><i className='zmdi zmdi-check'></i> 3.7 Litre Capacity</p>
                            <p><i className='zmdi zmdi-check'></i> Customise Recipes</p>
                            <p><i className='zmdi zmdi-check'></i> 2.5kW Required Power</p> */}
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4-1'>
                    <div className='single-ticket-pricing-table active text-center mb-100 wow fadeInUp' data-wow-delay='300ms'>
                        <h6 className='ticket-plan'>AX Series</h6>
                        <div className='ticket-icon'>
                            <img src='img/core-img/11.jpg' width='100%' height='100%' loading="lazy" alt=''/>
                        </div>
                        <h2 className='ticket-price'>Ala-carte</h2>
                        <span>On-Demand cooking, 24x7 Kitchens</span><br></br><br></br>
                        <a href="#contact" className='btn confer-btn sw-100 mb-30'>Get Quote <i className='zmdi zmdi-long-arrow-right'></i></a>
                        
                        <div className='ticket-pricing-table-details'>
                            <p><h6>Serves</h6> <i className='zmdi zmdi-check'></i> Min 1 - Max 4</p>
                            <p><h6>Vessel Capacity</h6><i className='zmdi zmdi-check'></i> 2 x 3.7 Litres </p>
                            <p><h6>Parallel Cooking</h6><i className='zmdi zmdi-check'></i> 2 Recipe only</p>
                            {/* <p><i className='zmdi zmdi-check'></i> 7.7 Litre Capacity</p>
                            <p><i className='zmdi zmdi-check'></i> Customise Recipes</p>
                            <p><i className='zmdi zmdi-check'></i> 5kW Required Power</p> */}
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4-1'>
                    <div className='single-ticket-pricing-table text-center mb-100 wow fadeInUp' data-wow-delay='300ms'>
                        <h6 className='ticket-plan'>QX Series</h6>
                        <div className='ticket-icon'>
                            <img src='img/core-img/22.jpg' width='100%' height='100%' loading="lazy" alt=''/>
                        </div>
                        <h2 className='ticket-price'>QSR model</h2>
                        <span>Mix of Bulk and On-demand cooking solution</span><br></br><br></br>
                        <a href='#contact' className='btn confer-btn w-100 mb-30'>3D experience <i className='zmdi zmdi-long-arrow-right'></i></a>
                        
                        <div className='ticket-pricing-table-details'>
                            <p><h6>Serves</h6> <i className='zmdi zmdi-check'></i> Min 2 - Max 100</p>
                            <p><h6>Vessel Capacity</h6><i className='zmdi zmdi-check'></i> 2 x 7.7 Litres </p>
                            <p><h6>Parallel Cooking</h6><i className='zmdi zmdi-check'></i> 2 Recipe only</p>
                            {/* <p><i className='zmdi zmdi-check'></i> 7.7 Litre Capacity</p>
                            <p><i className='zmdi zmdi-check'></i> Customise Recipes</p>
                            <p><i className='zmdi zmdi-check'></i> 5kW Required Power</p> */}
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4-1'>
                    <div className='single-ticket-pricing-table text-center mb-100 wow fadeInUp' data-wow-delay='300ms'>
                        <h6 className='ticket-plan'>CX Series</h6>
                        <div className='ticket-icon'>
                            <img src='img/core-img/robochef_co.jpg' width='100%' height='100%' loading="lazy" alt=''/>
                        </div>
                        <h2 className='ticket-price'>Commercial Model</h2>
                        <span>bulk Cooking, Catering units, Central Kitchens</span><br></br><br></br>
                        <a href='#contact' className='btn confer-btn w-100 mb-30'>3D experience <i className='zmdi zmdi-long-arrow-right'></i></a>
                        <div className='ticket-pricing-table-details'>
                            <p><h6>Serves</h6> <i className='zmdi zmdi-check'></i> Min 20 - Max 1000</p>
                            <p><h6>Vessel Capacity</h6><i className='zmdi zmdi-check'></i> 2 x 37 Litres </p>
                            <p><h6>Parallel Cooking</h6><i className='zmdi zmdi-check'></i> 2 Recipe only</p>
                            {/* <p><i className='zmdi zmdi-check'></i> 37 Litre Capacity</p>
                            <p><i className='zmdi zmdi-check'></i> Customise Recipes</p>
                            <p><i className='zmdi zmdi-check'></i> 10kW Required Power</p> */}
                        </div>
                    </div>
                </div>

            </div>
            </div>
    </section>
        {/* <!-- Our Schedule Area End --> */}


      </>
    )
  }
}

export default All
