import React from 'react'

class All extends React.Component {
  render () {
    return (
      <>
        
        
        {/* <!-- Our Schedule Area Start --> */}
        
        <section className='our-schedule-area section-padding-100' id='apps'>
          <div className='container'>
            <div className='row'>
              {/* <!-- Heading --> */}
              <div className='col-12'>
                <div className='section-heading-2 text-center wow fadeInUp' data-wow-delay='300ms'>
                  <p>Order Now. Try RoboChef</p>
                  <h4>Download the App available on</h4>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='schedule-tab'>
                  {/* <!-- Nav Tabs --> */}
                  <ul className='nav nav-tabs wow fadeInUp' data-wow-delay='300ms' id='conferScheduleTab' role='tablist'>
                    {/* <li className='nav-item'>
                      <a className='nav-link active' id='monday-tab' data-toggle='tab' href='#step-one' role='tab' aria-controls='step-one' aria-expanded='true'>Friday <br /></a>
                    </li> */}
                    {/* <!-- Nav Item --> */}
                    {/* <li className='nav-item'>
                      <a className='nav-link' id='tuesday-tab' data-toggle='tab' href='#step-two' role='tab' aria-controls='step-two' aria-expanded='true'>Sunday <br /> <span>July 14, 2019</span></a>
                    </li> */}
                  </ul>
                </div>

                {/* <!-- Tab Content --> */}
                <div className='tab-content' id='conferScheduleTabContent'>
                  <div className='tab-pane fade show active' id='step-one' role='tabpanel' aria-labelledby='monday-tab'>
                    {/* <!-- Single Tab Content --> */}
                    <div className='single-tab-content'>
                      <div className='row'>
                        <div className='col-12'>
                          {/* <!-- Single Schedule Area --> */}
                          <div className='single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp' data-wow-delay='300ms'>
                            {/* <!-- Single Schedule Thumb and Info --> */}
                            <div className='single-schedule-tumb-info d-flex align-items-center'>
                              {/* <!-- Single Schedule Thumb --> */}
                              <div className='single-schedule-tumb'>
                                <img src='/img/core-img/applestore.png' loading="lazy" alt='' />
                              </div>
                              {/* <!-- Single Schedule Info --> */}
                              <div className='single-schedule-info'>
                                <h5 style={{color: "white"}}>App Store</h5>
                                {/* <p>by <span>Saravanan S</span> / Ceo of Admatic</p> */}
                              </div>
                            </div>
                            {/* <!-- Single Schedule Info --> */}
                            {/* <div className='schedule-time-place'>
                              <p><i className='zmdi zmdi-time' /> 12-14 June 2019</p>
                              <p><i className='zmdi zmdi-map' /> Adyar, Chennai.</p>
                            </div> */}
                            {/* <!-- Schedule Btn --> */}
                            <a target="_blank" href='https://apps.apple.com/us/app/robotic-kitchen/id1456569907' className='btn confer-btn'>Download <i className='zmdi zmdi-long-arrow-right' /></a>
                          </div>

                          <div className='single-schedule-area d-flex flex-wrap justify-content-between align-items-center wow fadeInUp' data-wow-delay='300ms'>
                            {/* <!-- Single Schedule Thumb and Info --> */}
                            <div className='single-schedule-tumb-info d-flex align-items-center'>
                              {/* <!-- Single Schedule Thumb --> */}
                              <div className='single-schedule-tumb'>
                                <img src='/img/core-img/playstore.png' loading="lazy" width="90" height="90" alt='' />
                              </div>
                              {/* <!-- Single Schedule Info --> */}
                              <div className='single-schedule-info'>
                                <h5 style={{color: "white"}}>Google Play Store</h5>
                                {/* <p>by <span>Saravanan S</span> / Ceo of Admatic</p> */}
                              </div>
                            </div>
                            {/* <!-- Single Schedule Info --> */}
                            {/* <div className='schedule-time-place'>
                              <p><i className='zmdi zmdi-time' /> 12-14 June 2019</p>
                              <p><i className='zmdi zmdi-map' /> Adyar, Chennai.</p>
                            </div> */}
                            {/* <!-- Schedule Btn --> */}
                            <a target="_blank" href='https://play.google.com/store/apps/details?id=in.admatic.robochef&hl=en' className='btn confer-btn'>Download <i className='zmdi zmdi-long-arrow-right' /></a>
                          </div>
                        </div>


                        {/* <!-- More Schedule Btn --> */}
                      </div>
                    </div>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Our Schedule Area End --> */}


      </>
    )
  }
}

export default All
