import React from 'react'

class Problems extends React.Component {
  render () {
    return (
      <>
        
        
        {/* <!-- Our Schedule Area Start --> */}
        
        <section className="our-sponsor-client-area bg-img bg-gradient-overlay section-padding-100">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
                        <p>Problems</p>
                        <h4>What are the Problems faced by People?</h4>
                    </div>
                </div>
            </div>

            <center><img src="img/solution/problems.png" height="100" width="250"  alt="Robochef Solutions"></img><br></br><br></br></center>
            <div className="row">
                <div className="col-12">
                    <div className="our-sponsor-area d-flex flex-wrap">
                        <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                        <center> <h5 className='ticket-price'  style={{color: "white", fontSize:"1.5uv"}}>Consistet taste is not assured even by bigger restaurants</h5> </center>
                        </div>
                        <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                        <center> <h5 className='ticket-price' style={{color: "white", fontSize:"1.5uv"}}>Food Wastages & Low Profits.</h5> </center>
                        </div>
                        <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                        <center> <h5 className='ticket-price' style={{color: "white", fontSize:"1.5uv"}}>Chef availability and Manpower are difficult these days.</h5> </center>
                        </div>
                        <div className="single-sponsor wow fadeInUp" data-wow-delay="300ms">
                        <center> <h5 className='ticket-price' style={{color: "white", fontSize:"1.5uv"}}>High Co2 emissions & Wastage of Ingredients.</h5> </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        {/* <!-- Our Schedule Area End --> */}


      </>
    )
  }
}

export default Problems
