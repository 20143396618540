import React from 'react'

class Faq extends React.Component {
  render () {
    return (
      <>
        <section className="faq bg-primary">
        <div className='centerplease'><center>
  FAQ
  </center>
</div>
<br></br>

<div className="content">
<div>
  <input type="checkbox" id="question1" name="q"  className="questions"/>
  <div className="plus">+</div>
  <label for="question1" className="question">
  What are the recipes, i need to know about the recipe list?
  </label>
  <div className="answers">
  Curretly we have more than 800+ recipes from different cuisines, to know more about the recipes download our app using the links above. 
  </div>
</div>

<div>
  <input type="checkbox" id="question2" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question2" className="question">
  How to see a Robochef demo?
  </label>
  <div className="answers">
  If you are realy interested in Robochef, you can see a demo by just filling up your details in contact form. You can also contact us through call or support chat.
  </div>
</div>
  
<div>
  <input type="checkbox" id="question3" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question3" className="question">
  Will training be provided before operating?
  </label>
  <div className="answers">
  We provide all the essential training needed to operate the Robochef. It's easy to handle Robochef. Load - Start Cooking - Serve
  </div>
</div>

<div>
  <input type="checkbox" id="question4" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question4" className="question">
  What is the basic qualification needed to Operate ?
  </label>
  <div className="answers">
  Anyone who can read and write can operate our Robochef. It invloves basic process like loading ingredients in containers and ordering any recipes.
  </div>
</div>

<div>
  <input type="checkbox" id="question5" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question5" className="question">
  Can I Customise your Robochef System as per my need ?
  </label>
  <div className="answers">
  Yes. Robochef can be Customized as per your need. You can do it by sitting at your place. Just visit the 3D experience in the Product section.
  </div>
</div>

<div>
  <input type="checkbox" id="question6" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question6" className="question">
  Is it a safe system to Operate ?
  </label>
  <div className="answers">
  Robochef is one of the Safest system to Operate. It uses Induction heating technology for heating efficiently. The system is insulated in a better way so that the heating doesn't affect anything outside the cooking process.
  </div>
</div>

<div>
  <input type="checkbox" id="question7" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question7" className="question">
  Can Robochef make tiffin items like Dosa, Idly , Chapati?
  </label>
  <div className="answers">
  RoboChef variants listed here will not make tiffin items listed. This is because , Robochef was not designed to make tiffin items. Contact us to know about our other products that can make Idly, Dosa and Chapati.
  </div>
</div>

<div>
  <input type="checkbox" id="question8" name="q" className="questions"/>
  <div className="plus">+</div>
  <label for="question8" className="question">
  Tell me about the Maintenance?
  </label>
  <div className="answers">
  Our Service engineeres will visit you regularly for the RoboChef check-up process. To know more about Warranty and other stuff, Contact Us.
  </div>
</div>


</div>
<br></br>
    </section>
        {/* <!-- Our Speakings Area End --> */}
      </>
    )
  }
}

export default Faq
