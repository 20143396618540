import React from 'react'


import Layout from '../components/layout'
import Intro from '../components/index/welcome'
import About from '../components/index/about'
import All from '../components/index/all'
import Solution from '../components/index/solution'
import Problems from '../components/index/problems'
import Seenon from '../components/index/seenon'
import Process from '../components/index/process'
import Team from '../components/index/team'
import Services from '../components/index/services'
import Contact from '../components/index/contact'
import Faq from '../components/index/faq'
import Prodcuts from '../components/index/products'
import Gallery from '../components/index/gallery';

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
      <main id='main' >
        <About />
        <Problems />
        <Solution />
        <Process />
        <Prodcuts />
        <Gallery />
        <All />
        <Seenon />
        <Services />
        <Faq />
        {/* <Team /> */}
        <Contact />
      </main>
    </Layout>
  )
}