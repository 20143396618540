import React from 'react'

class Gallery extends React.Component {
  render () {
    return (
      <>
        <section className='our-speaker-area bg-img bg-gradient-overlay section-padding-100-60' style={{ backgroundImage: 'url(/img/bg-img/3.jpg)' }}>
          <div className='container'>
            <div className='row'>
              {/* <!-- Heading --> */}
              <div className='col-12'>
                <div className='section-heading text-center wow fadeInUp' data-wow-delay='300ms'>
                  <p>RoboChef Gallery</p>
                  <h4>RoboChef and It's Journey</h4>
                </div>
              </div>
            </div>

            
            <div className='row'>
              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/awards/1.jpeg' alt=''  height="100%" width="100%" loading="lazy" />
                  </div>
                  {/* <!-- Social Info --> */}
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>DemoDay Best Startup Title Award Winner</h5>
                    <p>StartupClub Bangalore</p>
                  </div>
                 
                </div>
              </div>

              {/* <!-- Single Speaker Area --> */}
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/awards/4.jpg' alt='' loading="lazy" height="100%" width="100%" />
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>TechCrunch Global TopPick 2019</h5>
                    <p>Berlin Germany</p>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/awards/2.jpg' alt='' height="100%" width="100%" loading="lazy" />
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>India Innovation Cup Winner</h5>
                    <p>Gitex Dubai 2019</p>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef-dina.jpeg' alt='' height="100%" width="100%" loading="lazy" />
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Dinamalar News Paper</h5>
                    <p>Media</p>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
              <a target="_blank" rel="noopener noreferrer" href='https://www.newindianexpress.com/good-news/2020/jan/24/your-new-chef-cooks-over-600-recipes-faster-with-no-lpg-2093651.html' >
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef_ie.jpg' alt=''  loading="lazy"/>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>The New Indian Express</h5>
                    <p>Media</p>
                  </div>
                </div>
                </a>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
                <a target="_blank" rel="noopener noreferrer" href="https://www.thehindu.com/life-and-style/food/watch-fully-automated-cooking-thanks-to-robochef/article30025435.ece">
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef_hindu.jpg' alt='' loading="lazy" />
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>The Hindu</h5>
                    <p>Media</p>
                  </div>
                </div>
                </a>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
                <a rel="noopener noreferrer" href="https://www.youtube.com/watch?v=r57EYBOdJW0" className="lightbox">
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef_poli.jpg'  alt='' height="100%" width="100%" loading="lazy"/>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Polimer News</h5>
                    <p>Tv & Media</p>
                  </div>
                </div>
                </a>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
                <a href="https://www.youtube.com/watch?v=xk0uUKRYZd4" className="lightbox">
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef_vendhar.jpg' loading="lazy" height="100%" width="100%"  alt='' />
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Vendhar Tv</h5>
                    <p>Tv & Media</p>
                  </div>
                </div>
                </a>
              </div>

              <div className='col-12 col-md-6 col-lg-4'>
              
                <a href="https://www.youtube.com/watch?v=FdFwdWkmW4k" className="lightbox">
                <div className='single-speaker-area bg-gradient-overlay-2 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <!-- Thumb --> */}
                  <div className='speaker-single-thumb'>
                    <img src='img/robochef_thanthi.jpg' alt='' height="100%" width="100%" loading="lazy"/>
                  </div>
                  {/* <!-- Info --> */}
                  <div className='speaker-info'>
                    <h5>Thanthi Tv</h5>
                    <p>Tv & Media</p>
                  </div>
                </div>
                </a>
                
              </div>

              {/* <div className='col-12'>
                <div className='more-speaker-btn text-center mt-20 mb-40 wow fadeInUp' data-wow-delay='300ms'>
                  <a className='btn confer-btn-white' href='#'>view all Speaker <i className='zmdi zmdi-long-arrow-right' /></a>
                </div>
              </div> */}
            </div>
            
          
          </div>

        </section>
        {/* <!-- Our Speakings Area End --> */}
      </>
    )
  }
}

export default Gallery
